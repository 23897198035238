<template>
    <ScriptItem
        class="tw-mb-1"
        :data="parseItems"
        :actions="['edit', 'remove']"
        @checked="customer.selected = !customer.selected"
        @edit="emit('edit:action', customer.id)"
        @remove="emit('remove:action', customer.id)"
    />
</template>

<script>
import ScriptItem from "@/components/admin/ScriptItem.vue";
import {dateToDateTime} from "@/helpers/dates";
import {CONTENT_ITEMS_SIZES} from '@/components/admin/ContentItems.vue';
import {subjectTypeObjectForTable} from "@/config/subjectType";

export default {
    name: "CustomerContent",
    emits: ['update:tab', 'edit:action', 'remove:action', 'input'],
    components: {
        ScriptItem,
    },
    props: {
        customer: {
            type: Object,
            required: true,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            required: true,
            default: false
        },
        checkboxAttrs: {
            type: Object,
            required: false,
            default: () => ({
                theme: 'square'
            })
        }
    },
    computed: {
        companyEnum() {
            return subjectTypeObjectForTable
        },
        parseItems() {
            return [
                {
                    key: 'checkbox',
                    type: 'checkbox',
                    value: this.customer.selected,
                    size: CONTENT_ITEMS_SIZES[0],
                },
                {
                    name: 'common.table.clientId',
                    value: this.customer.id,
                    size: CONTENT_ITEMS_SIZES[1],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.fio',
                    value: this.customer.username || '-',
                    size: CONTENT_ITEMS_SIZES[4],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.lastUpdateDate',
                    value: dateToDateTime(this.customer.updated_at),
                    size: CONTENT_ITEMS_SIZES[2],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.bankClientAttribute',
                    value: this.customer.bank_client_id ? this.$t("common.yes") : this.$t("common.no"),
                    size: CONTENT_ITEMS_SIZES[2],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.subjectType',
                    value: this.companyEnum[this.customer.is_company],
                    size: CONTENT_ITEMS_SIZES[4],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
            ]
        }
    },
    methods: {
        emit(name, $value) {
            this.$emit(name, $value)
        }
    }
}
</script>
