<template>
  <div class="customer-header">
    <h1 class="customer-header__title">{{ $t("customer.index") }}</h1>
  </div>
</template>

<script>
export default {
  name: "CustomerHeader",
}
</script>
