<template>
    <div class="customer-filter">

        <AppButton class="admin-application-filter__button admin-application-filter__item" theme="primary" @click="emit('toggle:filter-modal')">
            <AppIcon class="mr-10" icon="filter"/>
            {{ $t("common.filter") }}
        </AppButton>

        <div class="customer-filter__search">
            <SearchForm
                v-model="filter[type]"
                :type="type"
                :items="searchItems"
                fixed theme="application"
                @type="emit('set:filter-type', $event); type = $event"
                @input="delay(() => emit('set:filter',{[type]: $event}))"
            />
        </div>

        <div v-show="selectedCustomerCount > 0" class="customer-filter__actions">
            <p class="customer-filter__actions--text">{{ $t("common.selected", { value: selectedCustomerCount }) }}</p>

            <AppButton
                class="ml-auto mr-3 admin-application-filter__button admin-application-filter__item"
                theme="white-new"
                @click="emit('merge:clients')"
            >
                {{ $t("components.common.merge") }}
            </AppButton>
            <AppButton
                class="ml-auto mr-3 admin-application-filter__button admin-application-filter__item"
                theme="danger"
                @click="emit('remove:all')"
            >
              {{ $t("common.table.delete") }}
            </AppButton>
            <AppButton
                class="ml-auto mr-3 admin-application-filter__button admin-application-filter__item"
                theme="white-square"
                @click="emit('click:unselect')"
            >
                <AppIcon class="mr-10 unselect-icon" icon="unselect"/>
            </AppButton>
        </div>

        <div v-show="selectedCustomerCount === 0" class="ml-auto">
            <AppButton
                class="ml-auto admin-application-filter__button admin-application-filter__item"
                theme="success-flat"
                @click="emit('click:create')"
            >
                {{ $t("customer.createUser") }}
            </AppButton>
        </div>

    </div>
</template>

<script>
import SearchForm from "@/components/partials/SearchForm.vue";
import AppButton from "@/components/partials/AppButton.vue";
import ListUtils from "@/mixins/ListUtils";
import AppIcon from "@/components/partials/AppIcon.vue";

export default {
    name: "CustomerFilters",
    mixins: [ListUtils],
    emits: ["input", "set:filter", "set:filter-type", "click:create", "click:unselect"],
    components: {
        AppIcon,
        AppButton,
        SearchForm
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            type: this.defaultSelected
        }
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        },
        searchItems: {
            type: Array,
            required: true,
            default: () => []
        },
        selectedRegions: {
            type: Array,
            required: true,
            default: () => []
        },
        selectedCustomerCount: {
            type: [Number, undefined],
            default: 0
        },
        defaultSelected: {
            type: String,
            default: 1
        }
    },
    computed: {
        filter: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        emit(name, $value) {
            this.$emit(name, $value)
        }
    }
}
</script>

<style>
.unselect-icon {
    [fill]:not([fill=none]) {
        fill: transparent !important;
    }
    [stroke] {
        stroke: #949494;
    }
}
</style>
