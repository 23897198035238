<template>
    <main class="app-admin-page">
        <section class="customer">

            <customer-header/>

            <customer-filters
                v-model="filter"
                default-selected="username"
                :searchItems="searchItems"
                :selectedRegions="selectedRegions"
                :selectedCustomerCount="selectedCustomerCount"
                @toggle:filter-modal="toggleFilterModal"
                @set:filter-type="setFilterType"
                @set:filter="setFilter"
                @click:unselect="unselectAllUser"
                @remove:all="toggleDeleteModal($event, true)"
                @merge:clients="toggleMergeModal($event)"
                @click:create="create"
            />

            <customer-content
                v-model="activeTabKey"
                v-for="customer in listOrSkeleton" :key="customer.id"
                :customer="customer"
                :loading="isLoading"
                @edit:action="edit"
                @remove:action="toggleDeleteModal($event, false)"
            />
            <EmptyData v-show="isEmptyData"/>

            <app-pagination
                v-if="showPagination"
                v-model="pagination"
                :count="count"
                class="d-flex justify-content-between align-items-center mt-20"
                @update:page="fetch"
            />
        </section>

        <ApplicationModal
            v-if="modalFilterData.visible"
            v-bind="modalFilterData"
            v-on="modalFilterListeners"
        >
            <template #beginningOfForm>
                <div class="application-input-title">{{ $t('common.fio') }}</div>
                <AppInputA v-model="modalFilterData.model.username" class="application-custom-input"/>
            </template>
        </ApplicationModal>

        <ApplicationModal
            v-if="modalMergeData.visible"
            v-bind="modalMergeData"
            v-on="modalMergeListeners"
        >
            <template #beginningOfForm>
                <div class="customer-merge-form">
                    <merge-client
                        v-for="customer in selectedCustomer" :key="customer.id"
                        :customer="customer"
                        :main-customer="modalMergeData.model.link_to"
                        :is-loading="isLoading"
                        @client:checked="onClientChecked"
                    />
                </div>
            </template>
        </ApplicationModal>
    </main>
</template>

<script>
import {mapActions} from "vuex";
import {get, del, post} from "@/helpers/api";
import {PAGINATION} from "@/data/pages/Common";
import CustomerHeader from "@/components/admin/Customer/CustomerHeader.vue";
import CustomerFilters from "@/components/admin/Customer/CustomerFilters.vue";
import CustomerContent from "@/components/admin/Customer/CustomerContent.vue";
import AppPagination from "@/components/partials/common/Pagination.vue";
import EmptyData from "@/components/partials/common/EmptyData.vue";
import ApplicationModal from "@/components/modals/ApplicationModal.vue";
import AppInputA from "@/components/partials/AppInputA.vue";
import {
    MODAL_FILTER_DATA,
    MODAL_FILTER_MODEL,
    MODAL_DELETE_DATA,
    SEARCH_ITEMS,
    MODAL_MERGE_DATA
} from "@/data/pages/Customers";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import TreeProblemOptions from "@/services/TreeProblemOptions";
import MergeClient from "@/components/admin/Customer/Merge.vue";
import ConfirmController from "@/services/ConfirmController";

export default {
    name: "Customer",
    components: {
        MergeClient,
        ConfirmModal,
        AppInputA,
        ApplicationModal,
        EmptyData,
        AppPagination,
        CustomerHeader,
        CustomerFilters,
        CustomerContent
    },
    data() {
        return {
            isLoading: false,
            modalFilterVisible: false,
            activeTabKey: 'branch',
            count: 0,
            customers: [],
            regions: [],
            searchItems: SEARCH_ITEMS,
            treeOptions: new TreeProblemOptions(),
            filter: {"username": null},
            pagination: new PAGINATION(),
            modalFilterData: new MODAL_FILTER_DATA(),
            modalDeleteData: new MODAL_DELETE_DATA(),
            modalMergeData: new MODAL_MERGE_DATA(),
        }
    },
    computed: {
        listOrSkeleton() {
            if (this.isLoading) {
                return this.skeletonList
            }
            return this.customers
        },
        selectedCustomer() {
            if(!this.customers) {
                return [];
            }
            return this.customers.filter(el => el.selected);
        },
        selectedCustomerCount() {
            return this.selectedCustomer.length;
        },
        isEmptyData() {
            return !this.isLoading && this.customers.length === 0;
        },
        skip() {
            return (this.pagination.page - 1) * 10;
        },
        limit() {
            return Math.min(this.count, this.pagination.page * this.pagination.limit);
        },
        selectedRegions() {
            return [{name_uz: 'common.all', id: ''}, ...this.regions];
        },
        showPagination() {
            return this.count > this.pagination.limit && !this.isEmptyData;
        },
        tabLabels() {
            return {
                branch: this.$t('branch.show.tabs.branch'),
                atm: this.$t('branch.show.tabs.atm')
            };
        },
        modalFilterListeners() {
            return {
                reset: this.onFormReset,
                close: this.toggleFilterModal,
                submit: this.onFormSubmit,
                input: this.onFormInput
            }
        },
        modalMergeListeners() {
            return {
                close: this.toggleMergeModal,
                submit: this.mergeClients
            }
        },
        languagesList() {
            return Object.keys(this.$store.state.langsById).map(id => {
                const el = this.$store.state.langsById[id];

                return {
                    name: "common.languages.shorts." + el.code,
                    id: +id,
                    value: +id
                }
            })
        },
        sources() {
            return this.$store.state.incomingClientSources.map(({id, name}) => ({id, value: +id, name}));
        },
        skeletonList() {
            const list = []
            for (let index = 0; index < 10; index++) {
                list.push({
                    id: index,
                    skeleton: true,
                })
            }
            return list
        }
    },
    methods: {
        ...mapActions(["fetchLangsById"]),
        onClientChecked(id) {
            if (id === this.modalMergeData.model.link_to) {
                this.modalMergeData.model.link_to = 0;
                return;
            }

            this.modalMergeData.model.link_to = id;
        },
        unselectAllUser() {
            this.customers.forEach(i => i.selected = false);
        },
        findElementByKey(field) {
            return this.modalFilterData.renderInputs.find(({key}) => key === field);
        },
        toggleFilterModal() {
            this.modalFilterData.visible = !this.modalFilterData.visible;
        },
        onFormReset() {
            this.modalFilterData.model = new MODAL_FILTER_MODEL();
        },
        onFormSubmit() {
            this.filter = {"username": null};
            Object.assign(this.filter, this.modalFilterData.model);

            this.fetch()
                .finally(() => this.toggleFilterModal())
        },
        onFormInput(key, value) {
            this.modalFilterData.model[key] = value;
        },
        toggleDeleteModal(id, isMultiple = false) {
            this.modalDeleteData.isMultiple = isMultiple;
            this.modalDeleteData.customerId = id;
            const confirm = new ConfirmController()

            confirm.call().then(this.modalDeleteData.isMultiple ? this.removeAll : this.remove)
        },
        toggleMergeModal(id) {
            this.modalMergeData.customerId = id ? id : 0;

            this.modalMergeData.visible = !this.modalMergeData.visible;
        },
        setFilterType(key) {
            this.filter = {[key]: null};
        },
        setFilter(filter) {
            this.filter = filter;
            this.pagination.page = 1;
            this.fetch();
        },
        loadProductThemes() {
            this.treeOptions.loadOptions()
                .then(() => {
                    const productIdElement = this.findElementByKey("product_id");

                    productIdElement.flattenItems = this.treeOptions.flatOptions;
                    productIdElement.items = this.treeOptions.list;
                })
        },
        getUsernamesFromList(userList) {
            return userList?.map(user => user.username)?.join(', ') ?? "";
        },
        async fetch() {
            this.isLoading = true;

            const query = {
                ...this.filter,
                limit: this.pagination.limit,
                skip: this.skip,
                page: this.pagination.page
            };
            const queryString = Object.entries(query).filter(([, value]) => value).map(([key, value]) => `${key}=${value}`).join('&');

            const key = Object.keys(this.filter)[0]

            let url = `customers?${queryString}`
            if(this.filter[key] && key !== "username") {
                url = `customers/search-customer?where=${key}&query=${this.filter[key]}`
            }

            try {
                const response = await get(url)

                if (response.data.success) {
                    if(!this.filter[key] || key === "username") {
                        this.customers = response.data.data.data.map(item => ({...item, selected: false}));
                        this.count = response.data.data.count;
                    } else {
                        if(response.data.data?.data) {
                            this.customers = response.data.data.data.map(el => ({
                                ...el,
                                selected: false,
                            }));
                            this.count = response.data.data.data?.length
                        } else {
                            this.customers = response.data.data.map(el => ({
                                ...el,
                                selected: false,
                            }));
                            this.count = response.data.data?.length
                        }
                    }

                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            } catch (e) {
                this.customers = []
            } finally {
                this.isLoading = false;
            }
        },
        create() {
            this.$router.push({path: '/customers/new/'});
        },
        edit(id) {
            this.$router.push({path: `/customers/edit/${id}`, params: {id}})
        },
        getNotificationText(deletedUsers, notDeletedUsers) {
            let successText = "";
            let failedText = "";

            if (deletedUsers.length > 0) {
                successText = this.$t("requestsStatuses.success.removedWithUsers", {
                    users: this.getUsernamesFromList(deletedUsers)
                });
            }

            if (notDeletedUsers.length > 0) {
                if (deletedUsers.length === 0) {
                    return this.$t("requestsStatuses.failed.removedWithUsers", {
                        users: this.getUsernamesFromList(notDeletedUsers)
                    });
                }

                failedText = this.$t("requestsStatuses.failed.removedWithUsersWithSuccessDeleted", {
                    users: this.getUsernamesFromList(notDeletedUsers)
                });
            }

            return successText + failedText;
        },
        async remove() {
            const id = this.modalDeleteData.customerId;
            if (id === undefined || id === null) {
                this.$store.commit('notifications/error', this.$t("errors.chooseOneRecord"));
                return;
            }

            this.isLoading = true;
            try {
                const response = await del(`/customers/${id}`);
                if (response.data.success) {
                    this.$store.commit('notifications/error', {title: "requestsStatuses.success.index", text: "customer.customerSuccessDeleted", type: "success"});
                    await this.fetch();
                }
            } catch (e) {
                const errorMessage = this.$t("errors.failedDeleteCustomer");
                this.$store.commit('notifications/error', errorMessage);
            } finally {
                this.isLoading = false;
                this.modalDeleteData.visible = false;
            }
        },
        async removeAll() {
            if (this.selectedCustomer.length === 0) {
                this.$store.commit('notifications/error', this.$t("errors.chooseOneRecord"));
                return;
            }
            this.isLoading = true;

            try {
                const payload = {
                    customers: this.selectedCustomer.map(el => el.id)
                };
                const response = await del(`/customers`, payload);

                if (response.data.success) {
                    const deletedUsers = response.data.data.deleted;
                    const notDeletedUsers = response.data.data.not_deleted;

                    const notificationTitle = deletedUsers.length === 0 ? "errors.weHaveSomeProblems" : "requestsStatuses.success.index";
                    const notificationText = this.getNotificationText(deletedUsers, notDeletedUsers);

                    this.$store.commit('notifications/error', {title: notificationTitle, text: notificationText, type: "error"});
                    await this.fetch();
                }
            } catch (e) {
                this.$store.commit('notifications/error', this.$t("errors.failedDeleteCustomer"));
            } finally {
                this.isLoading = false;
                this.modalDeleteData.visible = false;
            }
        },
        async mergeClients() {
            const id = this.modalMergeData.model.link_to;
            if (!id) {
                this.$store.commit('notifications/error', this.$t("errors.chooseOneRecord"));
                return;
            }

            const formData = {
                link_to: id,
                customers: this.selectedCustomer?.reduce((result, item) => {
                    if (item.id !== id) {
                        result.push(item.id);
                    }
                    return result;
                }, [])
            }

            this.isLoading = true;
            try {
                const response = await post('/customers/join', formData);
                if (response.data.success) {
                    this.$store.commit('notifications/error', {title: "requestsStatuses.success.index", text: 'customer.customerSuccessMerged', type: "success"});
                    await this.fetch();
                }
            } catch (e) {
                const errorMessage = this.$t("errors.failedJoinCustomers");
                this.$store.commit('notifications/error', errorMessage);
            } finally {
                this.isLoading = false;
                this.modalMergeData.visible = false;
            }
        },
    },
    watch: {
        '$store.state.criticalLoaded': {
            handler(value) {
                if (value) {
                    const sourceIdElement = this.findElementByKey("source_id");
                    sourceIdElement.items = this.sources;
                }
            },
            immediate: true
        },
        '$store.state.langsById': {
            handler() {
                const searchElement = this.findElementByKey("language_id");
                searchElement.items = this.languagesList;
            },
            immediate: true
        }
    },
    created() {
        try {
            this.fetch();
            this.loadProductThemes();
            this.fetchLangsById()
        } catch (e) {
            this.$store.commit('notifications/error', 'errors.somethingWentWrong');
        }
    },
}
</script>

<style src="@/assets/scss/modules/pages/Customer/Customer.scss" lang="scss"></style>
