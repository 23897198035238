<template>
    <ScriptItem
        class="tw-mb-1 customer-script-item"
        :data="parseItems"
        :actions="[]"
        :checkboxAttrs="{ theme: 'round' }"
        @checked="emit('client:checked')"
        @click:wrapper="emit('client:checked')"
    />
</template>

<script>
import ScriptItem from "@/components/admin/ScriptItem.vue";
import {CONTENT_ITEMS_SIZES} from '@/components/admin/ContentItems.vue';

export default {
    name: "MergeClient",
    emits: ["client:checked", "edit:action", "remove:action"],
    components: {ScriptItem},
    props: {
        customer: {
            type: Object,
            required: true,
            default: () => ({})
        },
        mainCustomer: {
            type: Number,
            required: true,
            default: 0
        },
        isLoading: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    computed: {
        parseItems() {
            return [
                {
                    key: 'checkbox',
                    type: 'checkbox',
                    value: this.customer.id === this.mainCustomer,
                    size: CONTENT_ITEMS_SIZES[0],
                },
                {
                    name: 'common.table.clientId',
                    value: this.customer.id,
                    size: CONTENT_ITEMS_SIZES[1],
                    skeleton: this.isLoading,
                },
                {
                    name: 'common.table.fio',
                    value: this.customer.username || '-',
                    size: CONTENT_ITEMS_SIZES[3],
                    skeleton: this.isLoading,
                },
            ]
        },
    },
    methods: {
        emit(name) {
            this.$emit(name, this.customer.id);
        }
    }
}
</script>

<style lang="scss" scoped>
.customer-script-item {
    border-radius: 12px;
    border: 1px solid #E1E1E1;
    cursor: pointer;
}
</style>
